// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advanced-study-search-en-js": () => import("../src/pages/advanced-study-search.en.js" /* webpackChunkName: "component---src-pages-advanced-study-search-en-js" */),
  "component---src-pages-index-en-js": () => import("../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-results-en-js": () => import("../src/pages/results.en.js" /* webpackChunkName: "component---src-pages-results-en-js" */),
  "component---src-pages-sitemap-en-js": () => import("../src/pages/sitemap.en.js" /* webpackChunkName: "component---src-pages-sitemap-en-js" */),
  "component---src-pages-trial-details-en-js": () => import("../src/pages/trial-details.en.js" /* webpackChunkName: "component---src-pages-trial-details-en-js" */)
}

