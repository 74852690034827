import React from 'react';
// import ReactDOM from 'react-dom';
import AppProvider from 'store/provider';
import wrapPageElementWithTransition from 'helpers/wrapPageElement';
import 'bootstrap/dist/css/bootstrap.css';
import { MasterDataProvider } from 'store/masterDataContext';

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return (
    <MasterDataProvider>
      <AppProvider>{element}</AppProvider>
    </MasterDataProvider>
  );
};

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;

export const onRouteUpdate = win => {
  if (typeof window !== 'undefined' && window.history) {
    window.history.scrollRestoration !== 'manual'
      ? (window.history.scrollRestoration = 'manual')
      : null;
  }
  if (win.location && win.location.hash && window.location.hash !== '') {
    setTimeout(() => {
      console.log(
        'onRouteUpdate',
        document.querySelector(`${win.location.hash}`)
      );
      if (document.querySelector(`${win.location.hash}`)) {
        document.querySelector(`${win.location.hash}`).scrollIntoView(true);

        if (typeof window !== 'undefined') {
          window.scrollBy(0, -120);
        }
      }
    }, 1000);
  } else {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }
};

export const onPreRouteUpdate = () => {
  if (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.pathname.includes('results') &&
    window.location.hash !== '' &&
    !window.enableAnchors
  ) {
    window.location.hash = '';
  }
  if (
    typeof window !== 'undefined' &&
    !window.location.pathname.includes('/en')
  ) {
    window.location.pathname = `/en${window.location.pathname}`;
  }
};

export const shouldUpdateScroll = () => {
  if (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.pathname.includes('results') &&
    window.location.hash.replace('#', '') === '' &&
    !window.enableAnchors
  ) {
    return [0, 0];
  }
  if (window.enableAnchors && window.location.pathname.includes('results')) {
    if (window.innerWidth && window.innerWidth > 991) {
      return [0, 400];
    }
  }
};
